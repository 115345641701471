import React, { useRef } from "react";
import "./FundRequest.scss";
import { FilterWrapper } from "../../styles";
import { CSVLink } from "react-csv";
import Heading from "../../Component/DashboardHeader/Heading";
import { pdfData } from "../../utils/common";

const csvHeader = [
  { label: "Bank Name", key: "bankName" },
  { label: "Account Holder", key: "accountHolder" },
  { label: "Account Number", key: "accountNumber" },
  { label: "IFSC Code", key: "ifsc" },
  { label: "Billing Info", key: "billingInfo" },
  { label: "Cash Deposit Charges", key: "charges" },
];

function FundRequest() {
  const link = useRef(null);

  const bankList = [
    {
      bankName: "ICICI",
      branchName: "",
      accountHolder: "WEBETECHIES PRIVATE LIMITED",
      accountNumber: "337405000639",
      ifsc: "ICIC0003374",
      billingInfo: "Auto Billing",
      charges: "0",
    },
    {
      bankName: "YES BANK",
      branchName: "",
      accountHolder: "WEBETECHIES PRIVATE LIMITED",
      accountNumber: "020863400005852",
      ifsc: "YESB0000208",
      billingInfo: "Auto Billing",
      charges: "0",
    },
  ];

  const csvDownload = () => {
    const csvlink = link.current;
    csvlink.link.click();
  };
  const pdfDownload = () => {
    const tableHeader = [
      {
        bankName: "Bank Name",
        accountHolder: "Account Holder",
        accountNumber: "Account Number",
        ifsc: "IFSC Code",
        billingInfo: "Billing Info",
        charges: "Cash Deposit Charges",
      },
    ];

    pdfData(bankList, tableHeader, "Bank-Details", 250);
  };

  return (
    <>
      <div className="transtactionPage">
        <div className="tapWrapper">
          <Heading title={"Fund Request"} />
          {/* <div className="flex justify-between">
                <div className="strong">Fund Request</div>
              
              </div> */}
          <div className="fundRequestWrapper">
            <FilterWrapper className="flex items-center justify-between mb-30">
              <div className="filterBox">
                <div className="filter-text" style={{ maxWidth: "120px" }}>
                  Fund Request
                </div>

                <div
                  className="input-field flex"
                  style={{
                    marginLeft: "auto",
                    textAlign: "end",
                    maxWidth: "100px",
                  }}
                >
                  <button
                    className="filterButton"
                    onClick={() => {
                      pdfDownload();
                    }}
                  >
                    PDF
                  </button>
                  <button
                    className="filterButton"
                    onClick={() => {
                      csvDownload();
                    }}
                  >
                    CSV
                  </button>
                </div>
              </div>
              <CSVLink
                ref={link}
                data={bankList}
                headers={csvHeader}
                filename={"Bank details.csv"}
              ></CSVLink>
            </FilterWrapper>
            <div className="tableWrapper tableCard">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Bank Name</th>
                    {/* <th>Branch Name</th> */}
                    <th>Account Holder</th>
                    <th>Account Number</th>
                    <th>IFSC Code</th>
                    <th>Billing Info</th>
                    <th>Cash Deposit Charges</th>
                  </tr>
                </thead>
                <tbody>
                  {bankList.map((bank, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="card-th">Sr. N0.</span>
                          <span className="card-td">{index + 1}</span>
                        </td>
                        <td>
                          <span className="card-th">Bank Name</span>
                          <span className="card-td">{bank.bankName}</span>
                        </td>
                        {/* <td>{bank.branchName}</td> */}
                        <td>
                          <span className="card-th">Account Holder</span>
                          <span className="card-td">{bank.accountHolder}</span>
                        </td>
                        <td>
                          <span className="card-th">Account Number</span>
                          <span className="card-td">{bank.accountNumber}</span>
                        </td>
                        <td>
                          <span className="card-th">IFSC Code</span>
                          <span className="card-td">{bank.ifsc}</span>
                        </td>
                        <td>
                          <span className="card-th">Billing Info</span>
                          <span className="card-td">{bank.billingInfo}</span>
                        </td>
                        <td>
                          <span className="card-th">Cash Deposit Charges</span>
                          <span className="card-td">₹ {bank.charges}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundRequest;
