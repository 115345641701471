import React from 'react'

function IconBadge() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
  <path id="badge-check_18_" data-name="badge-check(18)" d="M17.5,35a8.005,8.005,0,0,1-6.137-2.857,7.674,7.674,0,0,1-6.237-2.268,8.014,8.014,0,0,1-2.319-6.361A7.686,7.686,0,0,1,0,17.5a8,8,0,0,1,2.858-6.137A7.684,7.684,0,0,1,5.126,5.126a7.982,7.982,0,0,1,6.36-2.319A7.69,7.69,0,0,1,17.5,0a8.005,8.005,0,0,1,6.137,2.857,7.685,7.685,0,0,1,6.237,2.268,8.014,8.014,0,0,1,2.319,6.361A7.686,7.686,0,0,1,35,17.5a8,8,0,0,1-2.858,6.137,7.684,7.684,0,0,1-2.268,6.237,8.026,8.026,0,0,1-6.36,2.319A7.69,7.69,0,0,1,17.5,35Zm-6.019-5.722a2.737,2.737,0,0,1,2.112.986,5.1,5.1,0,0,0,7.814,0,2.745,2.745,0,0,1,2.357-.977,5.1,5.1,0,0,0,5.524-5.524,2.737,2.737,0,0,1,.977-2.358,5.1,5.1,0,0,0,0-7.811,2.739,2.739,0,0,1-.977-2.358,5.1,5.1,0,0,0-5.523-5.524,2.731,2.731,0,0,1-2.358-.976,5.1,5.1,0,0,0-7.814,0,2.759,2.759,0,0,1-2.357.977,5.1,5.1,0,0,0-5.524,5.524,2.737,2.737,0,0,1-.977,2.358,5.1,5.1,0,0,0,0,7.811,2.739,2.739,0,0,1,.977,2.358,5.1,5.1,0,0,0,5.523,5.524c.083-.007.165-.01.246-.01Zm7.187-7.206,6.911-6.662a1.459,1.459,0,0,0-2.026-2.1l-6.93,6.681a1.47,1.47,0,0,1-2.1-.036L11.2,16.867A1.458,1.458,0,1,0,9.217,19L12.5,22.054a4.374,4.374,0,0,0,3.1,1.282,4.326,4.326,0,0,0,3.068-1.263Z" fill="#f1f1f1"/>
</svg>

  )
}

export default IconBadge