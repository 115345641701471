


export const NavigationList = [
  {
    title: "Dashboard",
    link: "/",
    active: false,
    default: true,
    icon: "IconWindow",
    subNav: [],
  },
  {
    title: "My Profile",
    link: "/profile",
    active: false,
    icon: "IconUser",
    subNav: [],
  },
  {
    title: "Transaction",
    link: "/transaction",
    active: false,
    icon: "IconBadge",
    subNav: [],
  },

  
  {
    title: "Fund Request",
    link: "/fund-request",
    active: false,
    icon: "IconDocument",
    subNav: [],
  },
  // {
  //   title: "Collect",
  //   link: "/collect",
  //   active: false,
  //   icon: "IconUser",
  //   subNav: [],
  // },
  // {
  //   title: "Payout",
  //   link: "/p",
  //   active: false,
  //   icon: "IconUser",
  //   subNav: [],
  // },
  // {
  //   title: "Cards",
  //   link: "/cards",
  //   active: false,
  //   icon: "IconUser",
  //   subNav: [],
  // },
  {
    title: "Settings",
    link: "",
    active: false,
    icon: "IconSetting",
    subNav: [
      {
               title: "Change Password",
              link: "/change-password",
               active: false,
              icon: "IconUser",
      
           },
    ],
  },
 
  // {
  //   title: "Developer",
  //   link: "",
  //   active: false,
  //   icon: "IconUser",
  //   subNav: [

  //     {
  //       title: "Api Keys",
  //       link: "/s",
  //       active: false,
  //       icon: "IconUser",

  //     },
  //     {
  //       title: "Api Keys",
  //       link: "/n",
  //       active: false,
  //       icon: "IconUser",

  //     }
  //   ]
  //   ,
  // },
  

  // {
  //   title: "Log Out",
  //   link: "/setting",
  //   active: false,
  //   icon: "IconUser",
  //   subNav: [],
  // },


];
