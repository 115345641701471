

import bgImg from  '../../assets/cardBgImg.svg';
import Animation from '../../assets/Animation -users.json'
import { useLottie } from "lottie-react";
import userImage from '../../assets/user_image.png'
import { useEffect, useState } from 'react';
import { getServiceOverview, getTransctionList, getTransctionOverview, getWalletBalance } from '../../utils/api';
import Loader from '../../Component/Loader/Loader';
import Heading from '../../Component/DashboardHeader/Heading';
import { isEmpty } from '../../utils/common';
import { useNavigate } from 'react-router-dom';




export default function Dashboard() {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [overviewData, setOverviewData] = useState([]);
  const [liveBalance, setLiveBalance] = useState('');
  const [totalApicall, setTotalApicall] = useState(0);

  const navigate = useNavigate();

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: Animation,
  };
  const { View } = useLottie(defaultOptions1);

  useEffect(() =>{
    getOverview();
    transactionList();
    getBallance();
  },[])

  const getOverview = () =>{
    setLoading(true)
    getTransctionOverview().then((resp) =>{
      setLoading(false)
      
        if(resp?.data?.code === "0000"){
          setOverviewData(resp?.data?.data)
        }else{
          if(resp?.data?.errorCodeList.lenght >0){
            console.log(resp?.data?.errorCodeList.lenght[0])
          }else{
            console.log(resp?.data?.message);
          }
        }
    });
  }

  const getBallance = () =>{
    getWalletBalance().then((resp) =>{
      if(resp?.data?.code === "0000"){
          setLiveBalance(resp?.data?.data?.liveBalance)
      }else{
        if(resp?.data?.errorCodeList.lenght >0){
          console.log(resp?.data?.errorCodeList.lenght[0])
        }else{
          console.log(resp?.data?.message);
        }
      }
    });
  }


  


  const transactionList = () => {
    const payload = {
      pagination: {
        pageNo: 0,
        pageSize: 10
      },
    };

    setLoading(true);
    getTransctionList(payload).then((resp) => {
      setLoading(false);
      if (resp?.data?.message === "SUCCESS") {
    
        setList(resp?.data?.data?.content);
       
      }
    });
  };


  useEffect(() =>{ 
    let count = 0 ;
    overviewData && overviewData.map((val) =>{
        console.log(val);
           count += val?.count;
    });

    setTotalApicall(count)
  },[overviewData]);


  const navigationDaital = (item) => {
    navigate(`/transaction/${item?.orderId}`, { state: { detail: item } });
  };

  return (
    <>

    <div className="Wrapper-dashbard">
      <Heading title={"Dashboard"} />
     <div className="lg-card-wrapper flex gap-20">
        <div className="lg-card ">
         
          <h3 className='fs_white fs-13'>Wallet Balance</h3>
          <h1 className='fs_white'>
          ₹ {liveBalance || 0}
          </h1>
         
          <img src = {userImage}></img>
  
    <div className='animationStyle'>{View}</div> 
          
        </div>
       
    
       {/* <CardCount title="Total Api Call"  value={totalApicall} border="border_orange"/> */}
       <CardCount title="Total Successful API Calls"  value={(overviewData[0]?.count) || 0} border="border_green"/>
       <CardCount title="Total Failed API Call"  value={(overviewData[1]?.count) || 0} border="border_red"/>
       <CardCount title="Total Pending API Calls"  value={(overviewData[2]?.count) || 0} border="border_orange"/>
    
       
       
       
        
      </div>
   {/* <div className='main-wrapper'>

      <h3 className='mb-30'>All products Collections</h3>
       <div className='lg-card-wrapper flex  gap-20' >
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         <Card title="Developer"/>
         
       </div>
       

  </div> */}

<div className="tapWrapper">
          <div className="flex justify-between">
            <div className="strong"
            style={{marginBottom:"10px"}}
            >Latest Transactions</div>
            <div className="searchBox">
            
            </div>
          </div>

          <div className="tableWrapper tableCard">
          <table className="table">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  {/* <th>User ID</th> */}
                  <th>Transaction ID</th>
                  <th>Date</th>
                  <th>Order Id</th>
                  <th>Subscribed Service</th>
                  <th>Subscribed Sub Service</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Opening Balance</th>
                  <th>Closing Balance</th>
                  
                </tr>
              </thead>
              <tbody>
                {!isEmpty(list) &&
                  list.map((item,i) => {
                    const inputDate = new Date(item?.createdDate);
    
    const formattedDate = inputDate.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

 let color = ""
    if(item?.status?.toUpperCase() === "SUCCESS"){
      color = "success";
    }else if(item?.status?.toUpperCase() === "REFUND"){
      color = "yellow";
    }else{
     color = "error"
    }
  
    return (
      <tr key={i}>
        <td>
        <span className="card-th">Sr. No.</span>
        <span className="card-td">{i +1}</span>
        </td>
        {/* <td>{item?.userId}</td> */}
        <td>
        <span className="card-th">Transaction ID</span>
        <span className="card-td">{item?.txnId}</span>
        
        </td>
        <td>
        <span className="card-th">Date</span>
        <span className="card-td">{formattedDate}</span>
          </td>
          <td>
                        <span className="card-th">Order Id</span>
                          <span className="link card-td" onClick={() =>navigationDaital(item)} >{item?.orderId}</span>
                          
                          </td>
        <td>
        <span className="card-th">Subscribed Service</span>
        <span className="card-td">{item?.subscribedService}</span>
          
          </td>
        <td>
        <span className="card-th">Subscribed Sub Service</span>
        <span className="card-td">{item?.subscribedSubService || "-"}</span>
        
        </td>
        <td>
        <span className="card-th">Status</span>
          <span className={"card-td "+ color}
          >{item?.status}</span>
          </td>
          <td>
        <span className="card-th">Amount</span>
        <span className="card-td">{item?.txnAmount}</span>
        </td>
        <td>
        <span className="card-th">Opening Balance</span>
        <span className="card-td">{item?.openingBalance}</span>
        
        </td>
        <td>
        <span className="card-th">Closing Balance</span>
        <span className="card-td">{item?.closingBalance}</span>
        </td>
       
       
      </tr>
    );
                  })}

                {isEmpty(list) && !loading && <tr style={{textAlign:"center",color:"red"}}>
                  <td colspan="100%" style={{textAlign:"center"}}>No Transaction Found</td>
                 
                  </tr>}
              </tbody>
            </table>
          </div>
        </div>
        
   
    </div>
    {loading && <Loader className="local" />}
    </>
  );
}

const Card = ({title, value, border="border_orange"}) =>{
  return(
    <>
      <div   className={`lg-card-2  max-height flex ${border}`}>
          <div className='self-center'>
          <p>{title}</p>
          <p>APIs</p>
          </div>
         </div>
    </>
  )
}
const CardCount = ({title, value,border=""}) =>{
  return(
    <>
     <div className={`lg-card-2  max-height flex ${border}`}>
      <div className='self-center'>
      <h4 className='fs-13'>{title}</h4>
          <h1>{value}</h1>
      </div>
     
       </div>
    </>
  )
}