import React, { useState } from 'react'
import { ProfileWrapper } from './style'
import { useSelector } from 'react-redux';
import Heading from '../../Component/DashboardHeader/Heading';

function Profile() {

  const [saltShow, setSaltShow] = useState(false);
  const [apiKeyShow, setApiKeyShow] = useState(false);
  const user = useSelector((state) => state.user);


  return (
    <ProfileWrapper>
        <Heading title={"Profile"} />
        <div className="subadmin_container pd-30">
       
              <div className=''>
                <div className="flex row">
                  <div className='InputWrapper'>
                     <label htmlFor="">Userame</label>
                    <input type="text" value={user?.user?.userName} disabled/>
                    </div>
                    <div className='InputWrapper'>
                     <label htmlFor="">Email</label>
                    <input type="text" value={user?.user?.email} disabled/>
                    </div>
                </div>
                <div className="flex row">
                  <div className='InputWrapper'>
                     <label htmlFor="">First Name</label>
                    <input type="text" value={user?.user?.firstName} disabled/>
                    </div>
                  <div className='InputWrapper'>
                    <label htmlFor="">Last Name</label>
                    <input type="text" value={(user?.user?.lastName) || "NA"} disabled/>
                  </div>
                </div>

                <div className="flex row">
                <div className='InputWrapper'>
                     <label htmlFor="">Status</label>
                    <input type="text" value={user?.user?.status} disabled/>
                    </div>
                  <div className='InputWrapper'>
                    <label htmlFor="">Pincode</label>
                    <input type="text" value={user?.user?.pincode} disabled/>
                  </div>
                </div>
                <div className="flex row">
                
                  <div className='InputWrapper'>
                    <label htmlFor="">ApiKey</label>

                    <button
                           className="eye_icon"
                           onClick={() =>setApiKeyShow(!apiKeyShow)}
                         >
                           {apiKeyShow ? (
                             <i class="fa fa-eye-slash" aria-hidden="true"></i>
                           ) : (
                             <i class="fa fa-eye" aria-hidden="true"></i>
                           )}
                      </button>

                    <input type={apiKeyShow?"text":"password"} value={user?.user?.apiKey} disabled/>
                  </div>
                  <div className='InputWrapper'>
                     <label htmlFor="">Salt</label>
                     <button
                           className="eye_icon"
                           onClick={() =>setSaltShow(!saltShow)}
                         >
                           {saltShow ? (
                             <i class="fa fa-eye-slash" aria-hidden="true"></i>
                           ) : (
                             <i class="fa fa-eye" aria-hidden="true"></i>
                           )}
                      </button>
                    <input type={saltShow?"text":"password"} value={user?.user?.salt} disabled/>
                    </div>
                </div>
             
              </div>
       
            </div>
      
       

    </ProfileWrapper>
  )
}

export default Profile