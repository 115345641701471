import React, { useState } from "react";
import { ProfileWrapper } from "../Profile/style";
import Heading from "../../Component/DashboardHeader/Heading";
import { isEmpty } from "../../utils/common";
import { changeAdminPassword } from "../../utils/api";
import Spinner from "../../Component/spinner/Spinner";

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const INIT_ERRORS = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function ResetPassword() {
  const [changePasswordData, setChangePasswordData] = useState({
    ...initialValues,
  });
  const [errors, setErrors] = useState({ ...INIT_ERRORS });
  const [passwordChanged, setPasswordChanged] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPassShow, setCurrentPassShow] = useState(false);
  const [newPassShow, setNewPassShow] = useState(false);
  const [confPassShow, setConfPassShow] = useState(false);

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setChangePasswordData({ ...changePasswordData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const submitHandler = () => {
    const error = {};

    if (isEmpty(changePasswordData.currentPassword)) {
      error.currentPassword = "Please enter current password";
    }
    if (isEmpty(changePasswordData.newPassword)) {
      error.newPassword = "Please enter your new password";
    }
    if (isEmpty(changePasswordData.confirmPassword)) {
      error.confirmPassword = "Please enter your confirm password";
    } else if (
      changePasswordData.confirmPassword !== changePasswordData.newPassword
    ) {
      error.confirmPassword = "New password and confirm password didn't match";
    }

    setErrors({ ...errors, ...error });

    if (isEmpty(error)) {
      setLoading(true);
      changeAdminPassword(changePasswordData).then((resp) => {
        setLoading(false);
        if (resp?.data?.success === true) {
          setPasswordChanged({
            msg: "Password changed successfully",
            type: "success",
          });
        } else {
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.message;
          }
          setPasswordChanged({ msg: msg, type: "error" });
        }
      });
    }
  };

  return (
    <ProfileWrapper>
      <Heading title={"Reset Password"} />
      <div className="subadmin_container resetPassword pd-30">
        <div className="">
          <div className="row">
            {!isEmpty(passwordChanged) && (
              <p className={`fs-12 mt-10 text-center ${passwordChanged.type}`}>
                {passwordChanged.msg}
              </p>
            )}
          </div>
          <div className="row">
            <div className="InputWrapper">
              <label htmlFor="">
                Current Password <span className="error">*</span>
              </label>
              <input
                type={currentPassShow?"text":"password"}
                name="currentPassword"
                value={changePasswordData.currentPassword}
                onChange={changeHandler}
              />

              <button
                className="eye_icon"
                onClick={() => setCurrentPassShow(!currentPassShow)}
              >
                {currentPassShow ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            </div>

            {!isEmpty(errors.currentPassword) && (
              <p className="error fs-12 mt-10">{errors.currentPassword}</p>
            )}
          </div>
          <div className="row">
            <div className="InputWrapper">
              <label htmlFor="">
                New Password <span className="error">*</span>
              </label>
              <input
                type={newPassShow?"text":"password"}
                name="newPassword"
                value={changePasswordData.newPassword}
                onChange={changeHandler}
              />
               <button
                className="eye_icon"
                onClick={() => setNewPassShow(!newPassShow)}
              >
                {newPassShow ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            </div>
            {!isEmpty(errors.newPassword) && (
              <p className="error fs-12 mt-10">{errors.newPassword}</p>
            )}
          </div>
          <div className="row">
            <div className="InputWrapper">
              <label htmlFor="">
                Confirm Password <span className="error">*</span>
              </label>
              <input
                type={confPassShow?"text":"password"}
                name="confirmPassword"
                value={changePasswordData.confirmPassword}
                onChange={changeHandler}
              />
              <button
                className="eye_icon"
                onClick={() => setConfPassShow(!confPassShow)}
              >
                {confPassShow ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            </div>
            {!isEmpty(errors.confirmPassword) && (
              <p className="error fs-12 mt-10">{errors.confirmPassword}</p>
            )}
          </div>
          <div className="flex row">
            <div className="" style={{ minWidth: "100%" }}>
              {!loading ? (
                <button className="btn" onClick={submitHandler}>
                  Submit
                </button>
              ) : (
                <button className="btn">
                  {" "}
                  <Spinner /> Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </ProfileWrapper>
  );
}

export default ResetPassword;
