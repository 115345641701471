import React from "react";

function IconDocument({color = "#000"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="18"
      viewBox="0 0 15 18"
    >
      <path
        id="assept-document"
        d="M15.462,4.152,12.848,1.537A5.216,5.216,0,0,0,9.136,0H5.75A3.754,3.754,0,0,0,2,3.75v10.5A3.754,3.754,0,0,0,5.75,18h7.5A3.754,3.754,0,0,0,17,14.25V7.864A5.215,5.215,0,0,0,15.462,4.152ZM14.4,5.212A3.764,3.764,0,0,1,14.988,6H11.75A.75.75,0,0,1,11,5.25V2.012a3.764,3.764,0,0,1,.788.586Zm1.1,9.037a2.25,2.25,0,0,1-2.25,2.25H5.75A2.25,2.25,0,0,1,3.5,14.25V3.75A2.25,2.25,0,0,1,5.75,1.5H9.136c.124,0,.242.024.364.035V5.25A2.25,2.25,0,0,0,11.75,7.5h3.715c.011.121.035.24.035.364ZM13.043,9.983a.75.75,0,0,1-.026,1.06L10.325,13.6a2.25,2.25,0,0,1-3.169-.013L6,12.56a.751.751,0,1,1,1-1.12L8.188,12.5a.75.75,0,0,0,1.092.03l2.7-2.573a.75.75,0,0,1,1.063.026Z"
        transform="translate(-2 0)"
        fill={color}
      />
    </svg>
  );
}

export default IconDocument;
