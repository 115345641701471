import React from 'react';

function IconWindow({color ="#000"}) {
  return (
    <svg id="_01_align_center" data-name="01 align center" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <path id="Path_5023" data-name="Path 5023" d="M8.25,8.25H0v-6A2.25,2.25,0,0,1,2.25,0h6ZM1.5,6.75H6.75V1.5H2.25a.75.75,0,0,0-.75.75Z" fill={color}/>
  <path id="Path_5024" data-name="Path 5024" d="M21.25,8.25H13V0h6a2.25,2.25,0,0,1,2.25,2.25ZM14.5,6.75h5.25V2.25A.75.75,0,0,0,19,1.5H14.5Z" transform="translate(-3.25)" fill={color} />
  <path id="Path_5025" data-name="Path 5025" d="M8.25,21.25h-6A2.25,2.25,0,0,1,0,19V13H8.25ZM1.5,14.5V19a.75.75,0,0,0,.75.75h4.5V14.5Z" transform="translate(0 -3.25)" fill={color} />
  <path id="Path_5026" data-name="Path 5026" d="M19,21.25H13V13h8.25v6A2.25,2.25,0,0,1,19,21.25Zm-4.5-1.5H19a.75.75,0,0,0,.75-.75V14.5H14.5Z" transform="translate(-3.25 -3.25)" fill={color} />
</svg>
  );
}

export default IconWindow;
