import React, { useRef, useState } from "react";
import "./Transaction.scss";
import { Link, useLocation } from "react-router-dom";
import { isEmpty } from "../../utils/common";
import { CSVLink } from "react-csv";
import Heading from "../../Component/DashboardHeader/Heading";

const TransactionDaitals = () => {

     const link = useRef();
    const location = useLocation();
    const{ detail }= location.state;
  
    const inputDateString = detail?.createdDate;
    const inputDate = new Date(inputDateString);
    
    const formattedDate = inputDate.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    const date = formattedDate.split(",")?.[0];
    const time = formattedDate.split(",")?.[1];


  const downloadCsv = () =>{
    const csvlink = link.current;
    csvlink.link.click();
  }
    
 const headers = [
    { label: "Status", key: "status" },
    { label: "Created By", key: "createdBy" },
    { label: "Order ID", key: "orderId" },
    { label: "Transaction ID", key: "txnId" },
    { label: "Subscribed Service", key: "subscribedService" },
    { label: "Subscribed Subservice", key: "subscribedSubService" },
    { label: "Opening Balance", key: "openingBalance" },
    { label: "Closing Balance", key: "closingBalance" },
    { label: "Transaction Amount", key: "txnAmount" },
    { label: "Transaction Narration", key: "txnNarration" },
   
  ];
 

  return (
    <div>
      <Heading title={"Transaction History"} />
   <div className="flex justify-between align-center gap-30 no-print">
    <Link to="/transaction">&#8592; back</Link>
    <div className="flex gap-10">
      <button className="btn"
      onClick={() =>window.print()}>Print
      </button>
    
      <button className="btn" onClick={downloadCsv}> CSV </button>
             
              <CSVLink
                ref={link}
                data={[detail]}
                headers={headers}
                filename="Transaction-deitals.csv"
              ></CSVLink>
    </div>
    </div>   
    <div className="TransactionDaitalsWrapper flex justify-center" >
      
      <div className="TransactionDaitals" >
        
        <div className="top text-center">
          <h2>Pay2Mobiles</h2>
          <h4>WEBETECHIES PRIVATE LIMITED</h4>
          <p>
            Ganga Bhawan, 1193/21 market, opposite sector 21 , Gurugram, India,
            <br /> Haryana +1 762-500-4104
          </p>
        </div>

        <div className="middle">
          <div>
          <div className="flex justify-between date">
            <div>{date}</div>
            <div>{time}</div>
          </div>
          </div>
          <div className="flex justify-between row">
            <div><strong>Status</strong></div>
            <div><strong>{detail?.status}</strong></div>
          </div>
          <div className="flex justify-between row">
            <div>Created By</div>
            <div>{detail?.createdBy}</div>
          </div>
          <div className="flex justify-between row">
            <div>Order ID</div>
            <div>{detail?.orderId}</div>
          </div>
        
          <div className="flex justify-between row">
            <div>Transaction ID</div>
            <div>{detail?.txnId}</div>
          </div>
          {!isEmpty(detail?.subscribedService) &&<div className="flex justify-between row">
            <div>Subscribed Service</div>
            <div>{detail?.subscribedService}</div>
          </div>}
          {!isEmpty(detail?.subscribedSubService) &&<div className="flex justify-between row">
            <div>Subscribed Subservice</div>
            <div>{detail?.subscribedSubService}</div>
          </div>}
          <div className="flex justify-between row">
            <div>Opening Balance</div>
            <div>₹ {detail?.openingBalance}</div>
          </div>
          <div className="flex justify-between row">
            <div>Closing Balance</div>
            <div>₹ {detail?.closingBalance}</div>
          </div>
          <div className="flex justify-between row amount">
            <div>Amount</div>
            <div>₹ {detail?.txnAmount}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TransactionDaitals;
