import React, { useState } from "react";
import LoginRight from "./LoginRight";
import Reset from "./Reset";
//import bg from "../../assets/images/backGroundImage.png";
import "./_styles/login.scss";

const Login = ({inRegister,setInRegister}) => {

    const [forgotPass, setForgotPass] = useState(false)
    const [generateOtp, setGenerateOtp] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)

    return (
        <div className="main-div flex">
        <div className="login_wrapper">
            
        
          
            {
                resetPassword
                    ? <Reset/>
                    : <LoginRight 
                        forgotPass={forgotPass} 
                        setForgotPass={setForgotPass} 
                        generateOtp={generateOtp} 
                        setGenerateOtp={setGenerateOtp} 
                        resetPassword={resetPassword} 
                        setResetPassword={setResetPassword} 
                        inRegister={inRegister} 
                        setInRegister={setInRegister}  
                    />
            }
            
        </div>
        </div>
    );
};

export default Login;
