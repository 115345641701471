import "./App.css";
import DashboardHeader from "./Component/DashboardHeader/DashboardHeader";
import Aside from "./Component/Aside/Aside";

//import Login from "./Pages/LoginPage/Login";
import Login from "./Pages/loginRegister/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./store/authSlice";
import { useEffect, useState } from "react";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Transaction from "./Pages/Transaction/Transaction";
import TransactionDaitals from "./Pages/Transaction/TransactionDaitals";
import ProtectedRoute from "./Component/ProtectedRoute";
import FundRequest from "./Pages/FundRequest/FundRequest";
import Profile from "./Pages/Profile/Profile";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";

function App() {
  const [isAuth, setIsAuth] = useState(null);
  const dispatch = useDispatch();

  let auth = JSON.parse(sessionStorage.getItem("token"));
  let authData = useSelector((state) => state.auth.auth);

  useEffect(() => {
    if (auth) {
      dispatch(login(auth));
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {!auth && (
          <Routes>
            <Route path="/*" element={<Login />} />
          </Routes>
        )}

        {auth && (
          <div className="dashboardWrapper">
            <Aside />
            <div className="dashboard-main">
              <DashboardHeader />
              <Routes>
                <Route path="/" exact element={< ProtectedRoute Component={Dashboard} />} />
                <Route path="/transaction" exact element={< ProtectedRoute Component={Transaction} />} />
                <Route  path="/transaction/:id" exact element={< ProtectedRoute Component={TransactionDaitals} />} />
                <Route  path="/fund-request" exact element={< ProtectedRoute Component={FundRequest} />} />
                <Route  path="/profile" exact element={< ProtectedRoute Component={Profile} />} />
                <Route  path="/change-password" exact element={< ProtectedRoute Component={ResetPassword} />} />
           
              </Routes>
            </div>
          </div>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
