import React from "react";

function IconSetting({color = "#000"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="settings-sliders_2_"
        data-name="settings-sliders(2)"
        transform="translate(0 0)"
      >
        <path
          id="Path_5017"
          data-name="Path 5017"
          d="M.75,3.562H2.8a2.8,2.8,0,0,0,5.4,0H17.25a.75.75,0,0,0,0-1.5H8.2a2.8,2.8,0,0,0-5.4,0H.75a.75.75,0,1,0,0,1.5ZM5.5,1.5A1.312,1.312,0,1,1,4.187,2.812,1.312,1.312,0,0,1,5.5,1.5Z"
          transform="translate(0 0)"
          fill={color}
        />
        <path
          id="Path_5018"
          data-name="Path 5018"
          d="M17.25,10.312H15.2a2.8,2.8,0,0,0-5.4,0H.75a.75.75,0,1,0,0,1.5H9.8a2.8,2.8,0,0,0,5.4,0H17.25a.75.75,0,0,0,0-1.5ZM12.5,12.375a1.312,1.312,0,1,1,1.312-1.312A1.312,1.312,0,0,1,12.5,12.375Z"
          transform="translate(0 -2.062)"
          fill={color}
        />
        <path
          id="Path_5019"
          data-name="Path 5019"
          d="M17.25,18.562H8.2a2.8,2.8,0,0,0-5.4,0H.75a.75.75,0,0,0,0,1.5H2.8a2.8,2.8,0,0,0,5.4,0H17.25a.75.75,0,0,0,0-1.5ZM5.5,20.625a1.312,1.312,0,1,1,1.312-1.312A1.312,1.312,0,0,1,5.5,20.625Z"
          transform="translate(0 -4.125)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconSetting;
