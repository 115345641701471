
import styled from "styled-components";

export const FilterWrapper = styled.div`
	//height: 74px;
	width: 100%;
	background: transparent linear-gradient(90deg, #00baf2 0%, #002970 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 3px #0029700d;
	border: 1px solid #00297024;
	border-radius: 10px;
	opacity: 1;
	overflow: auto;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	padding: 10px 24px;
	font-family: 'Noto Serif', serif;
	.filterBox {
		display: flex;
		gap: 10px;
		align-items: center;
		width: 100%;
       flex-wrap: wrap;
		.filter-text {
			font-size: 15px;
			color: #fff;
			font-weight: 600;
		}
		.input-field {
			flex: 1 1 142px;
			max-width: 200px;
			input,
			select {
				padding: 9px 10px;
				box-shadow: 0px 1px 2px #00297026;
				border-radius: 5px;
				background-color: #fff;
				font-size: 12px;
				width: 100%;
				border: 2px solid transparent;
				&:focus {
					border: 2px solid #ffd700;
					outline: none;
				}
			}
			&.date-filter {
				flex: 1 1 240px;
				max-width: 250px;
				padding: 0;
				box-shadow: 0px 1px 2px #00297026;
				border-radius: 5px;
				background-color: #fff;
				font-size: 12px;
				display: flex;
				align-items: center;
				position: relative;
				img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					&.img-1 {
						left: 8px;
					}
					&.img-2 {
						left: 125px;
					}
					&.img-3 {
						left: 240px;
					}
				}
				& > select {
					background-color: #edfafe;
					/* padding-left: 22px; */
					max-width: 120px;
					box-shadow: none;
					border-width: 0;
					&:focus-visible,
					&:focus {
						border-width: 0;
						outline: none;
					}
				}

				& > input {
					/* padding-left: 22px; */
					max-width: 130px;
					border-width: 0;
					box-shadow: none;
					&:focus-visible,
					&:focus {
						border-width: 0;
					}
				}
			}
			.filter-Button {
				gap: 10px;
				
				max-width: 100%;
			}
			.filterButton {
				background: #00baf2 0% 0% no-repeat padding-box;
				box-shadow: 0px 2px 3px #0029700d;
				border: 1px solid #00297024;
				padding: 8px 12px;
				border-radius: 5px;
				gap: 10px;
				color: #fff;
				cursor: pointer;
				.btn-loader {
					animation: spin 2s linear infinite;
				}
				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}
			&.csv {
				margin-left: auto;
			}
		}
	}
	@media only screen and (max-width: 767px){
		.filterBox{
			.filter-text {
				width: 100%;

			}
		}
	}
	@media only screen and (max-width: 500px){
		.filterBox{
			.input-field  {
				flex: 1 1 100%;
				max-width: 100%;
				&.date-filter
				{
					max-width: 500px;
				}
			}
		}
	}
`;

export const ModalPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgb(225 225 225 / 14%);
  border-radius: 10px;
  backdrop-filter: brightness(0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  

  .modal-popup {
    position: relative;
    width: 480px;
   min-height: 230px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 50px #0029702e;
    border-radius: 10px;
    padding: 20px;
    opacity: 1;
    text-align: center;
  }
  .error-popup {
    position: relative;
    min-width: 330px;
    min-height: 150px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 50px #0029702e;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    opacity: 1;
  }
 

  .modal_backgroud {
    background: url(./assets/subadmin_bg.png) no-repeat right
      bottom #ffffff;
  }

  .confirm_button {
    background: #00baf2 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #0029700d;
    border: 1px solid #00297024;
    padding: 8px 12px;
    border-radius: 5px;
    gap: 10px;
    color: #fff;
    cursor: pointer;
}

  @media only screen and (max-width: 767px) {
    .modal-popup {
      margin: 10px;
      position: relative;
      width: auto;
      height: auto;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 50px #0029702e;
      border-radius: 10px;
      opacity: 1;
      padding: 5px;
    }
  }
`;
