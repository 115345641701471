import React from 'react';

function IconUser({color = "#000"}) {
  return (
    <svg id="following_2_" data-name="following(2)" xmlns="http://www.w3.org/2000/svg" width="18.002" height="18" viewBox="0 0 18.002 18">
    <path id="Path_5020" data-name="Path 5020" d="M7.5,9A4.5,4.5,0,1,0,3,4.5,4.5,4.5,0,0,0,7.5,9Zm0-7.5a3,3,0,1,1-3,3,3,3,0,0,1,3-3Z" transform="translate(-0.75)" fill={color}/>
    <path id="Path_5021" data-name="Path 5021" d="M6.75,14A6.758,6.758,0,0,0,0,20.75a.75.75,0,0,0,1.5,0,5.25,5.25,0,1,1,10.5,0,.75.75,0,0,0,1.5,0A6.758,6.758,0,0,0,6.75,14Z" transform="translate(0 -3.5)" fill={color}/>
    <path id="Path_5022" data-name="Path 5022" d="M20.5,7.875A1.58,1.58,0,0,0,19,9.525a1.507,1.507,0,1,0-3,0c0,1.3,1.692,2.818,2.535,3.494a.744.744,0,0,0,.93,0c.843-.675,2.535-2.2,2.535-3.494a1.58,1.58,0,0,0-1.5-1.65Z" transform="translate(-3.999 -1.969)" fill={color}/>
  </svg>
  );
}

export default IconUser;
