import React from "react";
import { useEffect, useState } from "react";
import logo from "../../assets/logo_text.png";
import bar from "../../assets/bars-staggered-white.svg";

import "./Aside.scss";
import NavItem from "./NavItem";
import { NavigationList } from "./constants";
import { isEmpty, toggleMenu } from "../../utils/common";
import IconLogo from "../../assets/IconLogo";

function Aside() {
  const [navigation, setNavigation] = useState(NavigationList);

  const onNavItemClick = (e, i, key = "menu") => {
    e.stopPropagation();

    const _navigation = [...navigation];

    _navigation.map((val, index) => {
      if (key !== "subMenu") {
        if (val.link !== "" && val.active) {
          _navigation[index].active = !_navigation[index].active;
        }else if(val.link == "" && val.active){
         _navigation[index].active = !_navigation[index].active;
        }
      }else{
        if(val.link == "" && val.active){
          
          let subMenu = false;
    if (e.target.parentElement.tagName === "A") {
      const ul = e.target.parentElement.parentElement.parentElement;
      const classList = ul?.classList;

      if (classList?.[0] == "sub-menu-wrapper") {
        subMenu = true;
      }
    } else if (e.target.parentElement.tagName === "LI") {
      const ul = e.target.parentElement.parentElement;
      const classList = ul?.classList;
      if (classList?.[0] == "sub-menu-wrapper") {
        subMenu = true;
      }
    }
    if (!subMenu) {
      _navigation[index].active = !_navigation[index].active;
    }
         }
      }
    });
    let subMenu = false;
    if (e.target.parentElement.tagName === "A") {
      const ul = e.target.parentElement.parentElement.parentElement;
      const classList = ul?.classList;

      if (classList?.[0] == "sub-menu-wrapper") {
        subMenu = true;
      }
    } else if (e.target.parentElement.tagName === "LI") {
      const ul = e.target.parentElement.parentElement;
      const classList = ul?.classList;
      if (classList?.[0] == "sub-menu-wrapper") {
        subMenu = true;
      }
    }
    if (!subMenu) {
      _navigation[i].active = !_navigation[i].active;
    }

    setNavigation(_navigation);
  };

  const showSideBar = () => {
    toggleMenu(false);
  };

  return (
    <aside className="sideBar no-print">
      <div className="asideTop flex">
        <div className="logo">
          {/* <img src={logo} alt="Pay2Mobiles Logo" width={100} height={43} /> */}
          <IconLogo />
        </div>
        <div className="toggle-button d-m-block">
          <img
            src={bar}
            alt="toggle menu"
            width={19.4}
            height={15.36}
            onClick={showSideBar}
          />
        </div>
      </div>
      <ul className="menu-wrapper">
        {!isEmpty(navigation) &&
          navigation.map((navData, i) => {
            return (
              <>
                {
                  <NavItem
                    key={`nav-${i}`}
                    navData={navData}
                    onNavItemClick={onNavItemClick}
                    activeIndex={i}
                    subNavLength={navData?.subNav?.length || 0}
                  />
                }
              </>
            );
          })}
      </ul>
    </aside>
  );
}

export default Aside;
