import React, { useEffect, useState } from 'react';
import * as Styled from './style';
import { getVisiblePages } from './PaginationSetting';

export default function Pagination(props) {
	const [visiblePages, setVisiblePages] = useState(null);

	const { currentPage, totalPages, setCurrent } = props;

	useEffect(() => {
		const visible = getVisiblePages(currentPage, totalPages);
		setVisiblePages(visible);
	}, [props]);

	const changePage = (page) => {
		setCurrent(page - 1);
	};

	const prevPage = () => {
		if (currentPage + 1 > 1) {
			setCurrent(currentPage - 1);
		}
	};

	const NextPage = () => {
		if (totalPages > currentPage + 1) {
			setCurrent(currentPage + 1);
		}
	};

	return (
		<>
			{totalPages > 1 ? (
				<Styled.pagination>
					<button onClick={prevPage} className={currentPage < 1 ? 'prev-btn disabled' : 'prev-btn '} type="button">
						Prev
					</button>
					{visiblePages &&
						visiblePages.map((page, i) => {
							if (visiblePages[i] - visiblePages[i + 1] < -1) {
								return (
									<>
										<button onClick={() => changePage(page)} key={i} type="button" className={currentPage + 1 == page ? 'pagination-nav active' : 'pagination-nav'}>
											{page}
										</button>{' '}
										<span>...</span>{' '}
									</>
								);
							} else {
								return (
									<button onClick={() => changePage(page)} key={i} type="button" className={currentPage + 1 == page ? 'pagination-nav active' : 'pagination-nav'}>
										{page}
									</button>
								);
							}
						})}

					<button onClick={NextPage} className={totalPages <= currentPage + 1 ? 'next-btn disabled' : 'next-btn '} type="button">
						Next
					</button>
				</Styled.pagination>
			) : (
				''
			)}
		</>
	);
}
