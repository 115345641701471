
import React from "react";
import { isEmpty } from "../../utils/common";

import {Link, NavLink } from "react-router-dom";




import caret from "../../assets/caret.svg";
import IconWindow from "../../assets/IconWindow";
import IconUser from "../../assets/IconUser";
import IconUserGroup from "../../assets/IconUserGroup";
import IconDocument from "../../assets/IconDocument";
import IconSetting from "../../assets/IconSetting";
import IconBadge from "../../assets/IconBadge";



export default function NavItem({
  navData = {},
  subNavLength = 0,
  className = "",
  activeIndex,
  type ="menu",
  onNavItemClick = () => {},

}) {

 
  const setListClass = () => {
    let cls = className;
    if (!isEmpty(navData?.subNav)) {
      cls += " has-dropdown";
    }
    if (navData?.active) {
      cls += " active";
    }
    return cls;
  };

  const IconMap = {
    IconWindow: <IconWindow/>,
    IconUser: <IconUser/>,
    IconUserGroup:<IconUserGroup /> ,
    IconDocument: <IconDocument />,
    IconSetting: <IconSetting />,
    IconBadge: <IconBadge />,
  
  };

  
  return (
    <li 
 
    className={`${ "navItemWrapper"} ${setListClass()}`}
    subNavLength={subNavLength}

    onClick={
      isEmpty(navData?.subNav) ? (e) => onNavItemClick(e,activeIndex,"menu",navData?.link) :(type==="subLink")?() =>{alert("ffffff")}:(e) => onNavItemClick(e,activeIndex,"subMenu",navData?.link)
    }
  >


    {navData?.link ? (
      <NavLink to={navData.link} className="flex align-center nav-link"   accesskey={type}>

        <i className="icon">{navData?.icon && IconMap[navData.icon]}</i>
        <span className="nav-title">{navData.title}</span>
      </NavLink>
    ) : (
      <a className="flex align-center nav-link"    accesskey={type} >
        <i className="icon">{navData?.icon && IconMap[navData.icon]}</i>
        <span className="nav-title">{navData.title}</span>
        <div className="dropDown">
            <img src={caret} width={7} height={"auto"} />
        </div>
      </a>
    )} 
    
    {navData.subNav && (
      <ul className="sub-menu-wrapper">
        {navData.subNav.map((subNavData, i) => 
          <>
            {
              <NavItem key={`sub-nav-${i}`}  type="subLink" navData={subNavData} />
            }
          </>
        )}
      </ul>
    )}
  </li>
  );
}
