import React, { useEffect, useState } from "react";
import bar from "../../assets/bars-staggered.svg";
import "../../Pages/Dashboard/Dasboard.scss";
import IconUser from "../../assets/IconUser";
import arrow from "../../assets/caretB.svg";
import userIcon from "../../assets/User.png";
import bell from "../../assets/bell.svg";
import logo from "../../assets/logo.svg";
import { isEmpty, toggleMenu } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import { deleteUser, getUser } from "../../store/userSlice";
import { getUserAccount } from "../../utils/api";
import { ModalPopUp } from "../../styles";
import modalpopup from "../../assets/modalPopubg.svg";

function DashboardHeader() {
  const [logoutUser, setLogoutUser] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const showSideBar = () => {
    toggleMenu(true);
  };

  const logOut = () => {
    sessionStorage.clear();
    dispatch(logout(null));
    dispatch(deleteUser());
    navigate("/");
  };

  useEffect(() => {
    getUserAccount().then((resp) => {
      if (resp?.data?.success) {
        let userInfo = resp?.data?.data;
        dispatch(getUser(userInfo));
      }
    });
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      const token = JSON.parse(sessionStorage.getItem("token"));

      const token_ = token?.id_token;
      // const token_  = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4ODAwNTc4ODY2IiwiYXV0aCI6IlJPTEVfQURNSU4iLCJ1c2VySWQiOiI4NzJjN2UwOC0xNjgyLTRjOGYtODk0Mi04ZWFkMTMxYjliNmUiLCJpc090cFZhbGlkYXRlZCI6ZmFsc2UsImV4cCI6MTY5NDg0NTExM30.HD_Eeg2G-KUQzUVryiE5J_YzGjn6IG2BojcvcBjopGBVWjPUE6eRA7yJtBqPHqsF_2G9ad0hCg_pV85_N3lFUg";

      var base64Url = token_.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      jsonPayload = JSON.parse(jsonPayload);
      let expirytime = jsonPayload.exp;

      let expirationDate = new Date(expirytime * 1000);

      const Intervalid = setInterval(() => {
        try {
          if (expirationDate) {
            if (expirationDate <= Date.now()) {
              setLogoutUser(true);
              clearInterval(Intervalid);
            }
          } else {
            console.error("Token is not valid");
          }
        } catch (error) {
          console.error("An error occurred:", error.message);
        }
      }, 6000);
    }
  }, [sessionStorage.getItem("token")]);

  const sessionLogout = () => {
    sessionStorage.clear();
    dispatch(logout());
    dispatch(deleteUser());
    navigate("/");
  };

  return (
    <div className="dashboardHeader flex justify-between no-print">
      <div className="Header flex align-center">
        <div className="mr-20" onClick={showSideBar}>
          <img src={bar} alt="Pay2Mobiles Logo" width={19.4} height={15.36} />
        </div>
        <div className="mr-20 d-m-block">
          <img src={logo} alt="Pay2Mobiles Logo" width={100} height={"auto"} />
        </div>
        <div>
          <h2></h2>
        </div>
      </div>
      <div className="profile-icon flex align-center">
        {/* <div className="notification center">
       <div className='span center'> <span>2</span></div>
       <img src={bell} alt="dropdoen icon" width={18.48} height={19.39} />
       </div> */}
        <div className="user-icon center">
          {/* <span></span> */}
          <img src={userIcon} alt="dropdoen icon" />
        </div>

        <div
          className="name"
          title={`${
            !isEmpty(user?.user?.firstName) ? user?.user?.firstName : ""
          } ${!isEmpty(user?.user?.lastName) ? user?.user?.lastName : ""}`}
        >
          {`${!isEmpty(user?.user?.firstName) ? user?.user?.firstName : ""} ${
            !isEmpty(user?.user?.lastName) ? user?.user?.lastName : ""
          }`}
        </div>
        <div className="">
          <img src={arrow} alt="dropdoen icon" width={9.5} height={5.5} />
        </div>
        <div className="right_header_profile">
          <div className="menu">
            <p onClick={() => navigate("profile")}>My Profile</p>
            <p onClick={logOut}>Log Out</p>
          </div>
        </div>
      </div>



      {logoutUser && (
        <ModalPopUp>
          <div
            className="modal-popup"
            style={{
              backgroundImage: `url(${modalpopup})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "right",
              backgroundPositionY: "bottom",
              height: "170px",
            }}
          >
            <>
            <div className="flex justify-center mt-30">
              <label className="fs-20 ">Your session has expired. Please log in again to continue.</label>
            </div>
            <div className="flex justify-center gap-10 mt-30">
              <button className="confirm_button"
                onClick={() => sessionLogout()}
              >
                Ok
              </button>
            </div>
          </>
          </div>
          
        </ModalPopUp>
      )}

    </div>
  );
}

export default DashboardHeader;
