import Request from "./Request";
import urls from "./urls";

const apiRequest = new Request(
  () => {},
  () => {},
  () => {}
);


export const getUserLogin = (params) => {
 
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.USER_LOGIN}`,
    params
  );
};


export const getUserAccount = (params) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.login.GET_USER_ACCOUNT}`,
    params
  );
};
export const getWalletBalance = (params) => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.overview.WALLET_BALANCE}`,
    params
  );
};

export const forgetPasswordGenerateOtp = (params,) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.FORGET_PASSWORD_GENERATE_OPT}`,
    params
  );
};

export const LoginPasswordReset = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.RESET_PASSWORD}`,
    params
  );
};

export const changeAdminPassword = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.login.CHANGE_PASSWORD}`,
    params
  );
};

export const getTransctionList = (params) => {
  return apiRequest.post(
    `${urls.BASE_URL + urls.apiService.TRANSTCTION_LIST}`,
    params
  );
  }
export const getTransctionOverview = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.overview.TRANSTCTION_OVERVIEW}`,
   
  );
  }
export const getServiceOverview = () => {
  return apiRequest.get(
    `${urls.BASE_URL + urls.overview.TRANSTCTION_SERVICE_LIST}`,
   
  );
  }
