import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'


const ProtectedRoute = ({Component}) => {


    // const {user} = useSelector((state)=> state.user)
    
    useEffect(() => {
   

      let auth = JSON.parse(sessionStorage.getItem("token"));

        if(!auth){
            <Navigate to={"/"}/>
        }
    })


    

  return (
    <div>
        <Component />
    </div>
  )
}

export default ProtectedRoute