
const apiService = "/api-service";
const auth = "/auth"
export default {

BASE_URL: "https://api.pay2mobiles.com",
//BASE_URL: "",
  login: {
      USER_LOGIN: auth+"/api/authenticate",
      TRANSTCTION_LIST: apiService+"/transaction/list",
      GET_USER_ACCOUNT: apiService+"/user/account",
      FORGET_PASSWORD_GENERATE_OPT: auth+"/api/user/account/reset-password/init",
      RESET_PASSWORD: auth+"/api/user/account/reset-password/finish",
      CHANGE_PASSWORD : auth+"/api/user/account/change-password",
  },

  apiService: {
  
      TRANSTCTION_LIST: apiService+"/transaction/list",
     
  },
  overview: {
  
      TRANSTCTION_OVERVIEW: apiService+"/transaction/overview",
      TRANSTCTION_SERVICE_LIST: apiService+"/transaction/overview",
     WALLET_BALANCE: apiService+"/user/balance",
     
  },
}