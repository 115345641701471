import React from 'react'

function Heading({title}) {
  return (
    <h2 className='headingWrapper'>
      {title}
    </h2>
  )
}

export default Heading