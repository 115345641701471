import styled from "styled-components";

export const ProfileWrapper = styled.div`
  .subadmin_container {
    // border: 1px solid green;
    display: flex;
    justify-content: center;
    max-width: 80%;
    max-width: 600px;
    margin-top: 20px;
    background-color: #edfafe;
    box-shadow: 0px 2px 3px #0029700d;
    border: 1px solid #00297024;
    border-radius: 10px;
    padding: 30px;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    label {
      font-size: 12px;
      font-weight: 600;
    }
    & > div {
      flex-grow: 1;
    }
    .row {
      margin-bottom: 20px;
      gap: 20px;
      flex-grow: 1;
      & > div.InputWrapper {
        flex-grow: 1;
        max-width: calc(50% - 10px);
        border: none;
          height: 40px;
          outline: none;
          background-color: #fff;
          border: 2px solid transparent;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 41, 112, 0.149);
          font-size: 12px;
          padding: 4px 10px 4px;
          position: relative;
          button{
            position: absolute;
            right: 4px;
            top: 10px;
            background-color: transparent;
            border: none;
          }
        input {
          width: 100%;
          /* background: #f4f8f8; */
          border: none;
          background-color: #fff;
          /* height: 35px; */
          /* outline: none;
         
          border: 2px solid transparent;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 41, 112, 0.149); */
          font-size: 12px;
          /* padding: 9px 10px; */
          &:focus{
            outline: none;
          }
        }
      }
    }
  }

  .subadmin_col_container {
    padding: 15px;
    display: flex;
    gap: 2%;
    margin-bottom: 1%;
  }

  .subadmin_left_container {
    flex: 1;
    margin-left: 2%;
    /* Take up available space */
    padding: 10px;
    // gap: 5%;
  }
  .resetPassword{
    max-width: 400px;
    .row>div.InputWrapper{
      max-width: 100%;
    }
  }
`;
