/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import { getTransctionList } from "../../utils/api";
import { isEmpty, pdfData } from "../../utils/common";
import Pagination from "../../Component/Pagination/Pagination";
import Loader from "../../Component/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { FilterWrapper } from "../../styles";

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Heading from "../../Component/DashboardHeader/Heading";
import Spinner from "../../Component/spinner/Spinner";

const csvHeader = [
  { label: "User Id", key: "userId" },
  { label: "Transaction Id", key: "txnId" },
  { label: "Date", key: "createdDate" },
  { label: "Order Id", key: "orderId" },
  { label: "Subscribed Service", key: "subscribedService" },
  { label: "Subscribed Sub Service", key: "subscribedSubService" },
  { label: "Status", key: "status" },
  { label: "Amount", key: "txnAmount" },
  { label: "Opening Balance", key: "openingBalance" },
  { label: "Closing Balance", key: "closingBalance" },
]

const Transaction = () => {
  const [list, setList] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvLoader, setCsvLoader] = useState("");
  const [paginastion, setPginastion] = useState({
    currentPage: 0,
    totalPages: 0,
    pageSize: 10,
    totalElements:100,
  });

  const navigate = useNavigate();
  const link = useRef(null);

  useEffect(() => {
    transactionList("");
  }, [paginastion.currentPage]);

  const transactionList = (key="") => {
    const payload = {
      pagination: {
        pageNo: paginastion.currentPage,
        pageSize: paginastion.pageSize,
      },
    };

    if((key==="csv") || (key==="pdf")){
      payload.pagination = {
        pageNo: 0,
        pageSize: paginastion.totalElements,
      }
      if((key==="csv")){
        setCsvLoader("csv")
      }else{
        setCsvLoader("pdf")
      }
     
    }else{
      setLoading(true);
    }

  
    getTransctionList(payload).then((resp) => {
      setLoading(false);
      if (resp?.data?.message === "SUCCESS") {
        if(key===""){
        setList(resp?.data?.data?.content);
        setPginastion({
          ...paginastion,
          totalPages: resp?.data?.data?.totalPages,
          totalElements: resp?.data?.data?.totalElements
          ,
        });
      
      }else{
        setCsvLoader("")
        setCsvData(resp?.data?.data?.content);

        if(key==="csv"){
          setTimeout(() => {
            const csvlink = link.current;
					csvlink.link.click();
          }, 1);
        } else if(key==="pdf"){
          setTimeout(() => {
            pdfDownload(resp?.data?.data?.content);
          }, 1);
         
        }

					
      }
      }
    });
  };

  const setCurrentPage = (page) => {
     setPginastion({ ...paginastion, currentPage: page });
  };

  const navigationDaital = (item) => {
    navigate(`/transaction/${item?.orderId}`, { state: { detail: item } });
  };

  const filterChangeHandler = () => {

  };


  const csvDownload = (key) =>{

    transactionList(key);
   
  }



  const pdfDownload = (data) => {
		const tableHeader = [
			{
				userId: 'User Id',
				txnId: 'Transaction Id',
				createdDate: 'Date',
				orderId: 'Order Id',
				subscribedService: 'Subscribed Service',
				subscribedSubService:'Subscribed Sub Service',
				status: 'Status',
				txnAmount: 'Amount',
				openingBalance: 'Opening Amount',
				closingBalance: 'Closing Amount',
			},
		];

		pdfData(data, tableHeader, 'Transaction-History',400);
	};







  return (
    <>
      <div className="transtactionPage">
        <div className="tapWrapper">
        <Heading title={"Transaction History"} />
          {/* <div className="flex justify-between">
            <div className="strong">Transaction History</div>
          </div> */}

          <FilterWrapper className="flex items-center justify-between mb-30">
            <div className="filterBox">
              <div className="filter-text">Filter By</div>

              <div className="input-field">
                <select
                  placeholder="Select transactionType"
                  value={""}
                  name="transactionType"
                  onChange={filterChangeHandler}
                >
                  <option>select</option>
                </select>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  name="txnId"
                  placeholder="Transaction Id"
                  value={""}
                  onChange={filterChangeHandler}
                />
              </div>

              <div className="input-field date-filter">
                {/* <img className='img-1' src={calanderIcon} alt='calanderIcon' />
							     <select>
									<option>Main Wallet</option>
									<option >AEPS Wallet</option>
								 </select>
								 <img className='img-2' src={calanderIcon} alt='calanderIcon' /> */}
                <input
                  type="date"
                  placeholder="From"
                  name="fromDate"
                  value={""}
                  onChange={filterChangeHandler}
                />
                {/* <img className='img-3' src={calanderIcon} alt='calanderIcon' /> */}
                <input
                  type="date"
                  placeholder="To"
                  name="toDate"
                  value={""}
                  min={""}
                  onChange={filterChangeHandler}
                  disabled={""}
                />
              </div>
              <div className="input-field">
              
               {csvLoader ==="pdf"?
               <button className="filterButton">
              <Spinner />
             </button>
             : <button className="filterButton" onClick={() =>csvDownload("pdf")}>
                  PDF
                </button>}
               {csvLoader ==="csv"?
               <button className="filterButton">
              <Spinner />
             </button>
             : <button className="filterButton" onClick={() =>csvDownload("csv")}>
                  CSV
                </button>}
              </div>
            </div>
            <CSVLink ref={link}
             data={csvData}
             headers={csvHeader}
             filename={'Transaction-History.csv'}></CSVLink>
          </FilterWrapper>

          <div className="tableWrapper tableCard">
          <table className="table">
              <thead>
                <tr>
                  <td>Sr. No.</td>
                  {/* <th>User ID</th> */}
                  <th>Transaction ID</th>
                  <th>Date</th>
                  <th>Order Id</th>
                  <th>Subscribed Service</th>
                  <th>Subscribed Sub Service</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Opening Balance</th>
                  <th>Closing Balance</th>
                 
                </tr>
              </thead>
              <tbody>
                {!isEmpty(list) &&
                  list.map((item, index) => {
                    const inputDate = new Date(item?.createdDate);
    
    const formattedDate = inputDate.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    
 let color = ""
 if(item?.status?.toUpperCase() === "SUCCESS"){
   color = "success";
 }else if(item?.status?.toUpperCase() === "REFUND"){
   color = "yellow";
 }else{
  color = "error"
 }


                    return (
                      <tr key={index}>
                        <td>
                        <span className="card-th">Sr. No.</span>
                        <span className="card-td">{(paginastion.currentPage * paginastion.pageSize) + index +1}</span>
                        </td>
                        {/* <td>{item?.userId}</td> */}
                        <td>
                        <span className="card-th">Transaction ID</span>
                        <span className="card-td">{item?.txnId}</span>
                        
                        </td>
                        <td>
                        <span className="card-th">Date</span>
                        <span className="card-td">{formattedDate}</span>
                          </td>
                        <td>
                        <span className="card-th">Order Id</span>
                          <span className="link card-td" onClick={() =>navigationDaital(item)} >{item?.orderId}</span>
                          
                          </td>
                        <td>
                        <span className="card-th">Subscribed Service</span>
                        <span className="card-td">{item?.subscribedService}</span>
                          
                          </td>
                        <td>
                        <span className="card-th">Subscribed Sub Service</span>
                        <span className="card-td">{item?.subscribedSubService || "-"}</span>
                        
                        </td>
                        <td>
                        <span className="card-th">Status</span>
                          <span className={"card-td "+ color}
                          >{item?.status}</span>
                          </td>
                          <td>
                        <span className="card-th">Amount</span>
                        <span className="card-td">{item?.txnAmount}</span>
                        </td>
                        <td>
                        <span className="card-th">Opening Balance</span>
                        <span className="card-td">{item?.openingBalance}</span>
                        
                        </td>
                        <td>
                        <span className="card-th">Closing Balance</span>
                        <span className="card-td">{item?.closingBalance}</span>
                        </td>
                       
                        
                      </tr>
                    );
                  })}

                {isEmpty(list) && !loading && <tr style={{textAlign:"center",color:"red"}}>
                  <td colspan="100%" style={{textAlign:"center"}}>No Transaction Found</td>
                 
                  </tr>}
              </tbody>
            </table>
           

            {loading && <Loader className="local" />}
          </div>
          <Pagination
              currentPage={paginastion.currentPage}
              totalPages={paginastion.totalPages}
              setCurrent={setCurrentPage}
            />
        </div>
      </div>
    </>
  );
};

export default Transaction;
