import React from "react";

function IconUserGroup({color = "#000"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="users_3_"
        data-name="users(3)"
        d="M5.625,9.75A3.375,3.375,0,1,1,9,6.375,3.375,3.375,0,0,1,5.625,9.75Zm0-5.25A1.875,1.875,0,1,0,7.5,6.375,1.875,1.875,0,0,0,5.625,4.5ZM11.25,15A3.755,3.755,0,0,0,7.5,11.25H3.75A3.754,3.754,0,0,0,0,15v3H1.5V15a2.25,2.25,0,0,1,2.25-2.25H7.5A2.25,2.25,0,0,1,9.75,15v3h1.5Zm1.875-8.25A3.375,3.375,0,1,1,16.5,3.375,3.375,3.375,0,0,1,13.125,6.75Zm0-5.25A1.875,1.875,0,1,0,15,3.375,1.875,1.875,0,0,0,13.125,1.5ZM18,12a3.755,3.755,0,0,0-3.75-3.75h-3v1.5h3A2.25,2.25,0,0,1,16.5,12v3H18Z"
        fill={color}
      />
    </svg>
  );
}

export default IconUserGroup;
